.navbar {
  background: #c6ffdd; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f7797d,
    #fbd786,
    #c6ffdd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f7797d,
    #fbd786,
    #c6ffdd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;
  padding: 10px;
  position: sticky;
  top: 0;
}

.navbar-container {
  margin: 0 auto;
  max-width: 1380px;
}

.navbar-logo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.navbar-logo img {
  display: block;
  margin: 10px 1em;
  height: 50px;
}

.card-list {
  max-width: 1380px;
  margin: 0 auto;
}

.card {
  margin: 1.5em 1em;
  display: flex;
  flex-direction: row;
  justify-self: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  padding: 4rem 1rem;
  font-size: 4rem;
}

.logo {
  margin-bottom: 3rem;
  height: 200px;
}

.card-image {
  overflow: hidden;
  width: 260px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  height: 235px;
  width: auto;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  padding: 0px 10px;
}

.card-info .location {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.location span {
  text-transform: uppercase;
  font-size: 1em;
  line-height: 1.7px;
  letter-spacing: 5px;
  margin-right: 20px;
}

.location-icon {
  height: 14px;
  padding-right: 5px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
  margin: 10px 0px;
}

.card-title + div {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-info a {
  color: grey;
  font-size: 1em;
}

.book-button {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  color: #fff !important;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
  /* font-weight: 500; */
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #1976d2;
  border: 1px solid #1976d2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 10px 0px;
}

.book-button:hover {
  background-color: #fff;
  color: #1976d2 !important;
  border: 1px solid #1976d2;
}

@media screen and (max-width: 1280px) {
  .card-description {
    font-size: 0.9em;
  }
  .card-title {
    font-size: 1.9em;
    font-weight: bold;
    margin: 9px 0px;
  }
}

@media screen and (max-width: 991px) {
  .card-description {
    font-size: 0.8em;
  }
  .card-title {
    font-size: 1.8em;
    font-weight: bold;
    margin: 8px 0px;
  }
  .location span {
    text-transform: uppercase;
    font-size: 0.9em;
    line-height: 1.5px;
    letter-spacing: 5px;
  }

  .card-info a {
    color: grey;
    font-size: 0.9em;
  }
  .title {
    padding: 3rem 1rem;
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .navbar-logo {
    justify-content: center;
  }
  .title {
    padding: 2rem 1rem;
    font-size: 2rem;
  }
  .card {
    margin: 1.5em 1em;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: center;
  }
  .card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
  }
  .card-info .location {
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .card-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-image img {
    max-width: 100%;
    height: 100%;
  }
  .card-title {
    font-size: 1.6em;
    font-weight: bold;
    margin: 6px 0px;
  }
  .location span {
    font-size: 0.85em;
    line-height: 1.5px;
    letter-spacing: 3px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 375px) {
  .location span {
    text-transform: uppercase;
    font-size: 0.8em;
    line-height: 1.5px;
    letter-spacing: 2px;
  }

  .card-info a {
    color: grey;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 320px) {
  .location span {
    letter-spacing: 0px;
    margin-right: 5px;
  }
}
